import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage = () => (
  <Layout title="Legal Notice">
    <SEO title="Legal Notice" />
    <p>...</p>
  </Layout>
)

export default LegalPage
